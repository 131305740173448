// import { useState } from "react";
// import { SelectList } from "~/components";

import { classNames } from "~/utils";

export type currentTabProp = {
  id: number;
  status: string | string[];
};

type TabsProp = {
  id: number;
  status: string | string[];
  name: string;
  hasCounter?: boolean;
  icon?: any;
};

const Tabs = ({
  current,
  onSelect,
  counter,
  tabs,
}: {
  onSelect: ({ id, status }: currentTabProp) => void;
  current: number;
  counter: number;
  tabs: TabsProp[];
}) => {
  // const [selected, setSelected] = useState();
  return (
    <div>
      {/* Mobile select menu will be hidden for now because the platform got just Media part*/}
      {/* <div className="bg-whit rounded-full bg-btnColor-navBar sm:hidden">
      
        <SelectList
          items={tabs}
          selected={selected}
          defaultValue={tabs[0].name}
          onSelect={(value: any) => {
            setSelected(value);
            onSelect({
              id: value.id,
              status: value.status,
            });
          }}
        />
      </div> */}
      <div className="hidden sm:block">
        <div
        // className="shadow-[0_1px_2px_rgba(0,0,0,0.3)]"
        >
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div className="flex items-center" key={tab.id}>
                <p
                  onClick={() =>
                    onSelect({
                      id: tab.id,
                      status: tab.status,
                    })
                  }
                  key={tab.name}
                  className={classNames(
                    current === tab.id
                      ? "border-b-4 border-secondary text-base font-bold text-black"
                      : "text-gray-500 hover:text-gray-700",
                    "flex cursor-pointer items-center whitespace-nowrap  py-4 px-1 text-base font-medium hover:border-b-4 hover:border-secondary"
                  )}
                  aria-current={tab.id ? "page" : undefined}
                >
                  {tab.icon && (
                    <tab.icon
                      className={classNames(
                        current === tab.id
                          ? "text-orange-500"
                          : "text-gray-400",
                        "mr-1.5 h-5 w-5 flex-shrink-0 "
                      )}
                      aria-hidden="true"
                    />
                  )}
                  {tab.name}
                </p>
                {tab.hasCounter && counter > 0 && (
                  <span className="font-sm flex flex-shrink-0 items-center rounded-full  bg-orange-200 px-2 py-0.5 text-sm text-orange-800">
                    {counter}
                  </span>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
