import { Link } from "@remix-run/react";
import { type LiHTMLAttributes } from "react";

export interface IListItem extends LiHTMLAttributes<HTMLElement> {
  children: any;
  to?: string;
}

export const ListItem = ({ children, to, ...rest }: IListItem) => {
  if (to) {
    return (
      <div {...rest}>
        <Link to={to}>{children}</Link>
      </div>
    );
  }
  return <div {...rest}>{children}</div>;
};
