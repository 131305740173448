import type { ITeamProps } from "~/api/get_inkusion_data.server";
import { TeamCardList } from "./cards/team_card";
import dotsBackground from "~/assets/images/inkusion/hero-dot-bg.png";
import heroImage from "~/assets/images/inkusion/team-illustration.png";
import curvbackground from "~/assets/images/inkusion/wave-bg-top-white.svg";
import { Section } from "~/layout/section";

type TeamProp = {
  headerTile?: string;
  headerSubtitle?: string;
  backgroundUrl?: string;
  team: ITeamProps[];
};
export default function TeamSection({ team }: TeamProp) {
  return (
    <div
      style={{
        // height: "464px",
        backgroundImage: `linear-gradient(90deg,#FFAB00 0, #FFC500 100%)`,
        backgroundSize: "100%",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "repeat",
      }}
      className="relative mt-40"
    >
      <div
        className="absolute right-10 -top-28 
      hidden lg:block 2xl:right-14 3xl:left-2/4 3xl:ml-28"
      >
        <img
          alt="team-hero"
          className={
            "z-0 w-full object-contain lg:h-96 xl:h-full 3xl:h-[628px]"
          }
          src={heroImage}
        />
      </div>
      <div
        style={{
          zIndex: 0,
          backgroundImage: `url(${dotsBackground})`,
          backgroundSize: "100%",
          backgroundPosition: "100% 10%",
          backgroundRepeat: "repeat",
        }}
      >
        <Section
          className="relative z-40"
          // className="r
          // mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24"
        >
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-bold tracking-tight text-primary md:text-6xl">
                Unser Team
              </h2>
              <p className="text-lg text-primary lg:max-w-md 2xl:max-w-none 2xl:text-2xl">
                Ansprechpartnerinnen für den Arbeitsschwerpunkt Inklusion
              </p>
            </div>
            <TeamCardList team={team} />
          </div>
        </Section>
      </div>
      <div className="absolute bottom-0 z-40 w-full xl:relative xl:z-0">
        <img
          className="z-40 w-full object-contain"
          src={curvbackground}
          alt=""
        />
      </div>
    </div>
  );
}
