import type { ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "@remix-run/react";
import ReactModal from "react-modal";
import { classNames } from "~/utils";

type ModalProps = {
  title?: string;
  subtile?: string;
  open: boolean;
  setOpen?: (open: boolean) => void;
  hasIcon?: boolean;
  children?: ReactNode;
  type?: "success" | "warning";
  planDialgo?: boolean;
};
export default function Modal({
  title,
  // subtile,
  open,
  setOpen,
  // hasIcon = false,
  // type = "success",
  children,
  planDialgo,
}: // planDialgo = false,
ModalProps) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const handleCloseModal = () => {
    if (typeof setOpen === "function") {
      setOpen(false);
    }
    if (search.includes("openmoldal")) {
      navigate(pathname);
    }

    //this navigate(pathname); to override openmoldal=1 in the url in the case where the user comes from the start page.
  };
  return (
    <ReactModal
      // preventScroll={false}
      isOpen={open}
      ariaHideApp={false}
      role="div"
      // keepMounted
      //@ts-ignore
      // parentSelector={() => document.querySelector("#main_filter")}
      contentLabel={title}
      htmlOpenClassName="ReactModal__inklusion--open"
      style={{
        // overlay: {
        //   position: "fixed",
        //   top: 0,
        //   left: 0,
        //   right: 0,
        //   bottom: 0,
        //   backgroundColor: "transparent",
        //   // opacity: 0.8,
        // },
        content: {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: "1px solid transparent",
          overflow: "auto",
          backgroundColor: "#000",
          opacity: planDialgo ? 1 : 0.85,
          WebkitOverflowScrolling: "touch",
          borderRadius: "0",
          outline: "none",
          // padding: "20px",
        },
      }}
    >
      {!planDialgo ? (
        <div className="fixed inset-0 z-10 overflow-hidden">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0 lg:px-4 lg:pt-10">
            <div
              style={{
                // backgroundImage:
                // "radial-gradient(circle, rgba(245,234,214,1) 0%, rgba(242,242,242,1) 100%)",

                background:
                  "linear-gradient(0deg, rgba(250,252,252,1) 0%, rgba(252,244,214,1) 100%)",
                // border-radius: '16px',
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(12.3px)",
                border: "rgba(254, 250, 179, 0.06)",
              }}
              className={classNames(
                "relative flex h-auto w-full transform flex-col rounded-lg pt-5 pb-4 text-left shadow-xl transition-all sm:mt-8",
                planDialgo ? "md:max-w-[990px]" : "md:max-w-9xl"
              )}
            >
              <div className="flex justify-between">
                <div className="flex items-baseline justify-between  pb-6 sm:px-6 lg:px-20">
                  <h1 className="text-2xl font-bold tracking-tight text-textColor-light">
                    {title}
                  </h1>

                  <div className="flex items-center">
                    <button
                      type="button"
                      className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                      onClick={() => undefined}
                    >
                      <span className="sr-only">Filters</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <div
                  className={classNames(
                    "pr-5 sm:block",
                    title ? "lg:pr-20" : "lg:pr-10"
                  )}
                >
                  <button
                    type="button"
                    className="z-10 flex w-full rounded-full bg-black
           text-white hover:text-secondary
           focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    onClick={() => handleCloseModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-7 w-7" aria-hidden="true" />
                  </button>
                </div>
              </div>
              {title && (
                <div className="mx-20 h-[1px] bg-default opacity-10"></div>
              )}
              <div className="flex h-full flex-col py-4">{children}</div>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </ReactModal>
  );
}
