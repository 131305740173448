import { CardNew } from "~/shared";
import type { IMedia } from "~/types";

import { ListItem } from "~/shared";
import useLocalStorageState from "use-local-storage-state";
//@ts-ignore
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const CardList = ({
  cards,
}: // masonry = true,
{
  cards: IMedia[];
  // masonry: boolean;
}) => {
  const [favoriteList, setFavoriteList] = useLocalStorageState("favoriteList", {
    defaultValue: [],
  });
  // const [favoritleListItem, setItems] = useState(false);

  const isItemFavorite = (id: number) => {
    const itemInFavorite = favoriteList.length
      ? favoriteList.find((item: any) => item.id === id)
      : undefined;
    if (itemInFavorite) {
      return true;
    }
    return false;
  };

  const addToFavorite = (
    { name, firstPicture, id }: IMedia,
    itemInFavorite: any
  ) => {
    if (!itemInFavorite) {
      setFavoriteList([
        // @ts-ignore
        ...favoriteList,
        // @ts-ignore
        {
          name,
          firstPicture,
          id,
        },
      ]);
    } else {
      // @ts-ignore
      const newItems = favoriteList.filter((item: any) => item.id !== id);
      if (!newItems) {
        setFavoriteList([]);
      } else {
        setFavoriteList(newItems);
      }
    }
  };

  const getArticleLink = (id: number, name: string) =>
    `/search/main/${id}/${name.replace(/\s/g, "-")}`.replace("#", "");
  return (
    <div className="media-card-wrapper">
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 640: 1, 768: 2, 1024: 3, 1280: 4 }}
      >
        <Masonry
          gutter="15px"
          // className="mb-4"

          // to={articleLinkWithName(card.id, card.name)}
        >
          {cards &&
            cards.map((card) => {
              const isFavorite = isItemFavorite(card.id);
              return (
                <ListItem
                  // className="mb-4"
                  key={card.id}
                  // to={articleLinkWithName(card.id, card.name)}
                >
                  <CardNew
                    key={card.id}
                    {...card}
                    action={addToFavorite}
                    favoriteList={favoriteList}
                    isFavorite={isFavorite}
                    articleLink={getArticleLink(card.id, card.name)}
                  />
                </ListItem>
              );
            })}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};
