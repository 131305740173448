import React from "react";

import fallbackImage from "~/assets/images/fall_back_image.png";

interface IFullbackScreen {
  title: string;
  subtitle: string;
  size?: string;
}
const FullbackScreen: React.FC<IFullbackScreen> = ({
  title,
  subtitle,
  size,
}) => (
  // <div className="w-full flex-col justify-center align-middle">
  <div className="mx-auto flex max-w-8xl flex-col">
    <div
      className="flex justify-center align-middle"
      // alignSelf="center"
      // height={size !== 'small' ? '450px' : '40%'}
      // width={size !== 'small' ? '600px' : '90%'}
      // wrap
      // flex
    >
      <img alt="" src={fallbackImage} className="h-96 w-auto" />
    </div>
    <div
      className="mx-auto flex max-w-md flex-col justify-center text-center"
      // width={size !== "small" ? "50%" : "95%"}
      // alignSelf="center"
      // pad={{ vertical: "medium" }}
    >
      <p className="text-[18px] font-bold text-primary">{title}</p>
      <p className="py-2 text-[18px] text-primary">{subtitle}</p>
    </div>
  </div>
  // </div>
);

export default FullbackScreen;
