import { Link } from "@remix-run/react";

import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
// import type { LiHTMLAttributes } from "react";
import type { IMedia } from "~/types";
import { classNames, getImageUrl } from "~/utils";

import LockClosedIcon from "~/assets/icons/icon-lock-white.svg";
import { useEffect, useState } from "react";
export interface ICardProp extends IMedia {
  action?: (attributes: IMedia, itemInFavorite: boolean) => void;
  favoriteList?: any;
  articleLink?: string;
  isFavorite?: boolean;
}

export const CardNew = ({
  authorsAsString,
  name,
  firstPicture,
  extent,
  mediaKindsAsString,
  secondaryEducationAsString,
  firstAuthor,
  restriction,
  favoriteList,
  id,
  action,
  articleLink,
  isFavorite,
}: ICardProp) => {
  // this next line because of the use-local-storage-state lib does not support ssr yet (when the coponent loads === window is undefined)
  const [isInFavorite, setIsFavorite] = useState(false);

  const handleAddedTofavorite = () => {
    if (typeof action === "function") {
      let favoriteItem = {
        authorsAsString,
        name,
        firstPicture,
        extent,
        mediaKindsAsString,
        secondaryEducationAsString,
        firstAuthor,
        restriction,
        id,
      };
      //@ts-ignore
      action(favoriteItem, isFavorite);
    }
  };
  useEffect(() => {
    if (isFavorite) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [favoriteList, id, isFavorite]);
  const imageURl = firstPicture
    ? getImageUrl(firstPicture.file.url)
    : "should add fallback";

  return (
    <div
      //md:min-w-mediaCard
      className={classNames(
        "overflow-hidden rounded-2xl  bg-white shadow-card transition-shadow duration-300 hover:bg-slate-100",
        isInFavorite ? "border-2 border-secondary" : ""
      )}
    >
      <Link to={articleLink || "#"}>
        {imageURl && (
          <img
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            src={imageURl}
            className="h-56 w-full object-cover"
            alt=""
          />
        )}
      </Link>

      {restriction ? (
        <div className="absolute top-2 right-2 flex h-8 w-8 items-center justify-center rounded-md bg-black">
          <img
            className="h-[14px] w-[14px] text-white"
            src={LockClosedIcon}
            alt="restricted content"
          />
        </div>
      ) : null}
      <div className="border border-t-0 p-5">
        <div className="flex justify-between">
          <img
            className="-mt-12 h-14 w-14 rounded-full border-4 border-white object-cover"
            src={
              firstAuthor
                ? getImageUrl(
                    firstAuthor.avatar.small.url
                      ? firstAuthor.avatar.small.url
                      : getImageUrl(firstAuthor.avatar.url)
                  )
                : "https://lernplattform.limburg.voidmod.com/assets/fallback/avatar-b3886050a1f6d9c661a7e18475153b5be93b267925ff2bf5fa48cc5b4a0f14e1.png"
            }
            alt=""
          />
          <div className="-mt-3">
            {/* {id} */}
            <button
              type="button"
              onClick={handleAddedTofavorite}
              className={classNames(
                isInFavorite ? "bg-[#ffbe10]" : "",
                "rounded-md border p-1"
              )}
            >
              <StarIconSolid
                className={`h-5 w-5 ${
                  isInFavorite ? "text-white" : "text-[#D4D4D4]"
                }`}
              />
            </button>
          </div>
        </div>
        <Link to={articleLink || "#"}>
          <h4
            aria-label="Category"
            title="Visit the East"
            className="mb-2 inline-block
           text-cardTitle font-bold transition-colors duration-200"
          >
            {name}
          </h4>

          <div
            // dangerouslySetInnerHTML={{
            //   __html:
            //     description.length > 200
            //       ? description.slice(0, 200).concat("...")
            //       : description.slice(0, 200),
            // }}
            className="mb-2  cursor-pointer text-slate-400"
          >
            <p className="text-xs leading-5">{authorsAsString}</p>
            <p className="text-xs leading-5">
              {extent ? extent.name : ""} | {mediaKindsAsString} |{" "}
              {secondaryEducationAsString}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};
