import type { GroupBase, DropdownIndicatorProps } from "react-select";
import Select, { components } from "react-select";
// import { useField, useControlField } from "remix-validated-form";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import type { Props } from "react-select";

const colourStyles = {
  multiValue: (styles: any) => ({
    ...styles,
    color: "#050E3D",
    backgroundColor: "#fff",
    padding: "3px 5px",
    fontWeight: "",
    marginRight: 6,
    borderRadius: 15,
    border: "1px solid rgba(151,151,151, 1)",
    // "&:hover": {
    //   backgroundColor: "#F35D26",
    // },
  }),

  // MultiValueRemove controll the x icon when closing selected value
  MultiValueRemove: (base: any, state: any) => ({
    ...base,
    cursor: "pointer",
    padding: "2px 5px",
    borderRadius: 15,
    "&:hover": {
      backgroundColor: state.iselected ? "#000" : "#000",
      color: "#fff",
    },
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    border: "1px solid #E9E9E9",
    borderRadius: "6px",
    width: "100%",
    backgroundColor: state.isDisabled ? "#E9E9E9" : "#ffffff",
    color: state.isDisabled ? "#E9E9E9" : "#2A2A2A",
  }),
  input: (base: any) => ({
    ...base,
    "input:focus": {
      boxShadow: "none",
    },
  }),

  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "14px 16px 14px 16px",
    "&:hover": {
      backgroundColor: "#F6F6F6",
    },
  }),
};

export function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  placeholder,
  value,
  options,
  id,
  isDisabled,
  getOptionLabel,
  onChange,
}: Props<Option, IsMulti, Group>) {
  // const [valuefield, setValuefield] = useControlField<string[]>(name || "");
  // const { validate, error } = useField(name || "");

  const handleChangeFieldValue = (newSelection: any, e: any) => {
    // console.log("<EEEEE>", e, newSelection);

    if (typeof onChange === "function") {
      let newOptionValue;
      if (e.action === "clear") {
        newOptionValue = e.removedValues;
      } else if (e.action === "remove-value") {
        newOptionValue = e.removedValue;
      } else {
        newOptionValue = e.option;
      }
      let valueObj = {
        target: {
          name: newOptionValue.name,
          id: id,
          value: newOptionValue,
          checked: e.action === "remove-value" ? false : true,
        },
      };

      //@ts-ignore
      onChange(valueObj, newOptionValue, newSelection);
    }
  };
  const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
    const isWeiterFilterSelect = name === "authorIds" || name === "tagIds";
    return (
      <components.DropdownIndicator {...props}>
        {isWeiterFilterSelect ? (
          <MagnifyingGlassIcon className="h-5 w-5 text-primary" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-primary" />
        )}
      </components.DropdownIndicator>
    );
  };
  return (
    <>
      {/* {value && value.length
        ? value.map((val: any) => (
            <input
              // onChange={handleChangeFieldValue}
              type="hidden"
              name={props.name}
              key={val.id}
              value={val.id}
            />
          ))
        : undefined} */}
      <Select
        components={{
          //@ts-ignore
          DropdownIndicator,
          IndicatorSeparator: null,
        }}
        placeholder={placeholder}
        isMulti
        isClearable={false}
        id={id}
        options={options}
        isDisabled={isDisabled}
        //@ts-ignore
        // defaultValue={defaultInputValue}
        name={name}
        value={value}
        getOptionLabel={
          getOptionLabel && typeof getOptionLabel === "function"
            ? getOptionLabel
            : (option: any) => option.name
        }
        getOptionValue={(option: any) => option.id}
        onChange={handleChangeFieldValue}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 8,
          colors: {
            ...theme.colors,
            primary25: "#F6F6F6",
            primary: "#F35D26",
            neutral50: "#2A2A2A",
          },
        })}
      />
    </>
  );
}
