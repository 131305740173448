import { ArrowRightIcon } from "@heroicons/react/24/outline";

import BulbIcon from "~/assets/icons/bulb-1.svg";
import Newspaper from "~/assets/icons/newspaper-fold.svg";
import Info from "~/assets/icons/cursor-information.svg";
import Help from "~/assets/icons/help-wheel.svg";
import Mail from "~/assets/icons/mail-advertising-envelope.png";
import Support from "~/assets/icons/support.png";
import MediaIcon from "~/assets/icons/folder-media.svg";

type ICardProp = {
  title: string;
  description: string;
  anchor: string;
  externalLinks: boolean;
  icon?: string;
};

const SvgSrc = {
  fundamentals: BulbIcon,
  "newspaper-fold": Newspaper,
  "cursor-information": Info,
  "mail-advertising-envelope": Mail,
  support: Support,
  "help-wheel": Help,
  media: MediaIcon,
};
export const InklusionCard = ({
  title,
  description,
  anchor,
  externalLinks,
  icon,
}: ICardProp) => {
  // fix netx line for using icon
  //@ts-ignore
  const svgUrl = SvgSrc[externalLinks ? icon : anchor];
  return (
    <div className="flex rounded-lg bg-white shadow-lg md:relative md:flow-root md:h-72 md:max-w-xs">
      <div className="-mt-6 w-full pl-6 pr-3 pb-3">
        {/* className="mb-7" */}
        <div className="mb-7">
          <span className="inline-flex items-center justify-center rounded-full bg-secondary p-3 shadow-lg md:p-4">
            <img
              alt="icon"
              src={svgUrl}
              className="h-4 w-4 text-black md:h-7 md:w-7"
              aria-hidden="true"
            />
          </span>
        </div>
        <h4 className="mb-3 font-bold text-textColor-primary">{title}</h4>
        <div className="flex flex-col justify-between">
          {/* <p>{description}</p> */}
          <div
            // className="mx-auto
            // text-sm font-normal leading-6
            // text-textColor-primary lg:col-start-1 lg:row-start-1 lg:max-w-none"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <div className="mt-4 md:absolute md:bottom-4 md:mt-0">
            <a
              target={externalLinks ? "_blank" : undefined}
              href={externalLinks ? anchor : `#${anchor}`}
              className="inline-flex items-center rounded-none
              border-b border-primary pl-1 pb-1 text-sm font-medium text-textColor-primary hover:border-gray-200"
              rel="noreferrer"
              // rel="noreferrer"
            >
              Mehr erfahren
              <ArrowRightIcon
                className="ml-3 h-5 w-6 text-textColor-dark"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
