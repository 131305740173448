// import { GetScreenSize } from "~/utils";
import { CardMinimal, ListItem } from "~/shared";
// import type { IMedia } from "~/types";

export function ThemenList({ data }: { data: Array<any> }) {
  // const location = useLocation();

  const articleLinkWithName = (id: number, name: string) =>
    `/themenschwerpunkte/${id}/${name.replace(/\s/g, "-")}`;

  return (
    <div className="media-card-wrapper">
      <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {data && data.length
          ? data.map((media) => {
              return (
                <ListItem
                  className="mb-1"
                  key={media.id}
                  to={articleLinkWithName(media.id, media.name)}
                >
                  <CardMinimal {...media} />
                </ListItem>
              );
            })
          : null}
      </ul>
    </div>
  );
}
