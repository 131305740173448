import { CardList } from "./list";
import theme from "~/theme/default";
import { classNames } from "~/utils";
import type { IMedia } from "~/types";
import { Link } from "@remix-run/react";
// import Select from "react-select";
// import { SearchField } from "~/shared";
// import { useState } from "react";

// const filterInklusionOption = [
//   {
//     label: "Inklusiver RU",
//     value: "inklusionru",
//   },
//   {
//     label: "Inklusiver RUs",
//     value: "inklusiondru",
//   },
//   {
//     label: "Inklusiver RUss",
//     value: "inkldsdusdionru",
//   },
// ];

export const RelatedMediaSection = ({
  mediaCards,
  title,
  masonry = true,
  to,
}: {
  title: any;
  mediaCards: IMedia[];
  masonry: boolean;
  to: string;
}) => {
  // const [query, setSearchQuery] = useState("");
  const { button } = theme;
  const { base, color } = button;
  return (
    <div
      id="media"
      className="w-full pt-8 pb-3
    md:pb-0 md:text-left 
  "
    >
      <div
        className="mx-auto 
      md:max-w-[calc(100%_-_120px)]  
      xl:max-w-[calc(100%_-_320px)] 
      3xl:max-w-8xl"
      >
        <div className="mb-10 xl:mb-12">{title}</div>
        {/* <div className="flex max-w-2xl flex-col justify-between md:flex-row">
          <SearchField
            name="inkusionSearchfield"
            id="inkusionSearchfield"
            formWrapperClassName="flex-1 md:mr-6"
            placeholder="Suche..."
            formLink={`/search/main?query=${query}`}
            value={query}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="mt-4 md:mt-0">
            <Select
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                borderRadius: 6,
              })}
              defaultValue={filterInklusionOption[0]}
              name="inklusionSelectFilter"
              isSearchable={false}
              options={filterInklusionOption}
            />
          </div>
        </div> */}
      </div>

      <CardList cards={mediaCards} />

      <div className="flex w-full justify-center">
        <Link to={to} className={classNames(base, color.primary, "px-9")}>
          Mehr laden
        </Link>
      </div>
    </div>
  );
};
