// import { useEffect, useState } from "react";
// import { useField, useControlField } from "remix-validated-form";
import { classNames } from "~/utils";

// const getDefaultChecked = (checkedDefaultValue: Array<any>, itemID: string) => {
//   if (checkedDefaultValue && checkedDefaultValue.length) {
//     return (
//       checkedDefaultValue.filter(
//         (item: { name: string; id: string }) => item.name == itemID
//       ).length > 0
//     );
//   }

//   return false;
// };

type CheckBoxProps = {
  name: string;
  id: string;
  setFieldValue: (e: any, value?: any) => void;
  label: string;
  subTitle?: string;
  checked?: boolean;
  value?: any;
  formFieldValue?: {
    name: string;
    id: number;
  };
  setSelectedId?: (value: number) => void;
};

// replace it with form inout
export const CheckBox = ({
  name,
  id,
  label,
  subTitle,
  setFieldValue,
  value,
  checked,
  formFieldValue,
  setSelectedId,
}: // value,
CheckBoxProps) => {
  const handleChangeFieldValue = (e: any, id: string) => {
    // setValue(e.target.value);
    // setDefaultChecked(e.target.checked);
    if (typeof setFieldValue === "function") {
      setFieldValue(e, formFieldValue);
    }
    if (typeof setSelectedId === "function" && formFieldValue) {
      // set the id of selecte itmes to use it to move selected item to top of the list
      setSelectedId(formFieldValue.id);
    }
  };

  return (
    <div
      className={classNames(
        "relative flex",
        checked ? "bg-white" : "",
        checked && subTitle ? "shadow-mainFilter" : "",
        subTitle ? "my-2 rounded-lg p-4" : ""
      )}
      // key={id}
    >
      <div className={subTitle ? "mt-1" : "mt-0"}>
        <input
          // {...getInputProps({
          //   id: id,
          //   value: id,
          //   onChange: (e) => handleChangeFieldValue(e, id),
          // })}
          // autoComplete="off"
          id={id}
          onChange={(e) => handleChangeFieldValue(e, id)}
          value={value}
          name={name}
          type="checkbox"
          checked={checked}
          className="h-4 w-4 rounded border-gray-300 text-[#F35D26] focus:ring-indigo-500"
        />
      </div>
      <div className="ml-3">
        <span
          // htmlFor={`${label}`}
          className={classNames(
            checked ? "font-bold text-[#F35D26]" : "text-primary",
            "text-sm",
            subTitle ? "font-normal" : "font-medium"
          )}
        >
          {label}
        </span>
        {subTitle && (
          <p id={`${label}`} className="text-xs text-primary">
            {subTitle}
          </p>
        )}
      </div>
    </div>
  );
};
