// import type { LiHTMLAttributes } from "react";
import type { IMedia } from "~/types";
import { getImageUrl } from "~/utils";

import {
  ArrowUturnRightIcon,
  TrashIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/24/outline";

export interface ICardProp extends IMedia {
  handleCopyFavoritItem?: (id: number, name: string) => void;
  handleRemoveFavoriteItem?: (id: number) => void;
  handleShareFavoriteList?: () => void;
  handleOpenFavoriteListItem?: (id: number, name: string) => void;
}

export const FavoriteListCard = ({
  id,
  name,
  firstPicture,
  handleCopyFavoritItem,
  handleRemoveFavoriteItem,
  handleShareFavoriteList,
  handleOpenFavoriteListItem,
}: ICardProp) => {
  return (
    <div className="overflow-x-hidden border-t border-gray-200 py-3">
      <div className="flex flex-1 items-center">
        <div className="flex-shrink-0">
          <img
            className="h-24 w-32 rounded-xl object-cover"
            src={
              firstPicture
                ? getImageUrl(firstPicture.file.url)
                : "should add fallback"
            }
            alt=""
          />
        </div>
        <div className="w-full flex-1 pl-4">
          <div className="flex h-24 w-full flex-col justify-between">
            <p className="font-semibold text-primary md:text-lg">{name}</p>

            <div className="flex pb-2">
              <button
                onClick={() => {
                  if (typeof handleOpenFavoriteListItem === "function") {
                    handleOpenFavoriteListItem(id, name);
                  }
                }}
                type="button"
                className="inline-flex items-center border-none pr-2  text-sm font-medium leading-4 text-textColor-primary  hover:text-btnColor-hoverPrimary focus:outline-none focus:ring-2 focus:ring-btnColor-secondary focus:ring-offset-2"
              >
                <ArrowUturnRightIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Öffnen
              </button>
              <button
                onClick={() => {
                  if (typeof handleCopyFavoritItem === "function") {
                    handleCopyFavoritItem(id, name);
                  }
                }}
                type="button"
                className="ml-1 inline-flex items-center border-none pr-2  text-sm font-medium leading-4 text-textColor-primary  hover:text-btnColor-hoverPrimary focus:outline-none focus:ring-2 focus:ring-btnColor-hoverPrimary focus:ring-offset-2"
              >
                <ArrowUpOnSquareIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Teilen
              </button>
              <button
                onClick={() => {
                  if (typeof handleRemoveFavoriteItem === "function") {
                    handleRemoveFavoriteItem(id);
                  }
                }}
                type="button"
                className="ml-1 inline-flex items-center border-none pr-2  text-sm font-medium leading-4 text-textColor-primary  hover:text-btnColor-hoverPrimary focus:outline-none focus:ring-2 focus:ring-btnColor-hoverPrimary focus:ring-offset-2"
              >
                <TrashIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
