import type { ITeamProps } from "~/api/get_inkusion_data.server";
import { getImageUrl } from "~/utils";
const TeamCard = ({ avatar, name, jobTitle, description }: ITeamProps) => {
  return (
    <li className="rounded-lg bg-white py-10 px-6 text-center xl:text-left">
      <div className="space-y-6 xl:space-y-10">
        <img
          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
          src={avatar ? getImageUrl(avatar.url) : ""}
          alt=""
        />

        <div className="space-y-1 text-lg font-medium leading-6">
          <h2 className="text-3xl font-bold leading-7 text-textColor-light">
            {name}
          </h2>
          {jobTitle && (
            <p className="text-xs leading-5 text-slate-500">{jobTitle}</p>
          )}
          <p className="pt-4 text-slate-500">{description}</p>
        </div>
      </div>
    </li>
  );
};

export const TeamCardList = ({ team }: { team: ITeamProps[] }) => {
  return (
    <ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
      {team && team.map((person) => <TeamCard {...person} key={person.name} />)}
    </ul>
  );
};
