import React from "react";
import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "@remix-run/react";
import { classNames } from "~/utils";

// import Button from "./tw_button";
import theme from "~/theme/default";
interface ISearchBox {
  setSearchQuery: (value: string) => void;
  query?: string;
  defaultValue?: string;
  isLandingPage?: boolean;
  formAction: string;
  placeHolder?: string;
  onSubmit?: () => void;
}
const SearchBox: React.FC<ISearchBox> = ({
  isLandingPage = false,
  formAction,
  query,
  onSubmit,
  setSearchQuery,
}) => {
  const { button } = theme;
  const { base } = button;
  const navigate = useNavigate();
  return (
    <div className="flex w-full lg:justify-center lg:align-middle">
      <div
        className={classNames(
          isLandingPage ? "justify-center" : "justify-start ",
          "relative flex w-full items-center lg:justify-between"
        )}
      >
        {isLandingPage && (
          <Link to="search/main?openmoldal=1" className="hidden md:block">
            <button
              type="button"
              onClick={() => null}
              className={classNames(
                "rounded-[24px] bg-textColor-primary px-8 py-2 text-white hover:bg-btnColor-hoverPrimary lg:mr-6",
                base
                // navBar
              )}
            >
              {" "}
              <AdjustmentsHorizontalIcon
                className={classNames(
                  "-ml-0.5 mr-2 hidden h-4 w-4 group-hover:text-gray-500 md:block"
                )}
                aria-hidden="true"
              />
              <span className="text-xs font-medium md:text-sm">
                Lehrplanfilter
              </span>
            </button>
          </Link>
        )}
        <div
          className={classNames(
            "min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6"
            // isLandingPage ? "hidden" : "block"
          )}
        >
          <div className="flex items-center py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none lg:px-6 xl:px-0">
            <div className="w-full">
              {/* <label htmlFor="q" className="sr-only">
                Search
              </label> */}
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 hidden items-center  pl-3 md:flex">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-black"
                    aria-hidden="true"
                  />
                </div>
                <input
                  // type="text"
                  value={query}
                  // search.changeSearchTerm(e.currentTarget.value)
                  onChange={(e: any) => setSearchQuery(e.currentTarget.value)}
                  //handleSearch(e)
                  // onKeyPress={(e: any) => null}
                  // id="search"
                  // defaultValue={defaultValue}
                  name="q"
                  className={classNames(
                    isLandingPage ? "bg-[#F6F6F6]" : "bg-white",
                    `block w-full rounded-full py-3
                  pl-10 pr-3 text-sm placeholder-gray-500 shadow-[0_1px_3px_rgba(0,0,0,0.134479)] focus:border-btnColor-secondary focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-btnColor-secondary sm:text-sm`
                  )}
                  placeholder={
                    isLandingPage ? "ru-digital durchsuchen …" : "Suche …"
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {/* MOBILE Btn START PAGE */}
        <div className="ml-1 flex-col md:hidden">
          <button
            type="button"
            onClick={() => {
              navigate(formAction);
              if (typeof onSubmit === "function") {
                onSubmit();
              }
            }}
            className={classNames(
              // open ? "text-gray-900" : "text-gray-500",
              base,
              // navBar,
              "group rounded-full bg-btnColor-secondary px-2.5 text-xs font-normal text-white"
            )}
          >
            {" "}
            <MagnifyingGlassIcon
              className={classNames(
                // open ? "text-gray-600" : "text-textColor-light",
                "h-6 w-6 text-white"
              )}
              aria-hidden="true"
            />
            {/* <span>Lernplanfilters</span> */}
          </button>
        </div>
        {/* END MOBILE Btn START PAGE */}
        <div className="hidden md:block">
          {/* {typeof onSubmit === "function" ? (
            <button
              onClick={() => {
                navigate(formAction);
                onSubmit();
              }}
              type="button"
              className="ml-2 inline-flex items-center rounded-full border border-transparent bg-btnColor-secondary px-4  py-2 text-xs font-medium text-white shadow-sm hover:bg-btnColor-hoveSecondary focus:outline-none 
              focus:ring-2 focus:ring-btnColor-secondary focus:ring-offset-2 md:px-8 md:text-sm lg:ml-8"
            >
              {isLandingPage ? "Medien finden" : "Suchen"}
            </button>
          ) : (
            <Link
              prefetch="render"
              to={formAction}
              // type="submit"
              className="ml-2 inline-flex items-center rounded-full border border-transparent bg-btnColor-secondary px-4  py-2 text-xs font-medium text-white shadow-sm hover:bg-btnColor-hoveSecondary focus:outline-none 
       focus:ring-2 focus:ring-btnColor-secondary focus:ring-offset-2 md:px-8 md:text-sm"
            >
              {isLandingPage ? "Medien finden" : "Suchen"}
            </Link>
          )} */}
          <button
            onClick={() => {
              navigate(formAction);
              if (typeof onSubmit === "function") {
                onSubmit();
              }
            }}
            type="button"
            className="ml-2 inline-flex items-center rounded-full border border-transparent bg-btnColor-secondary px-4  py-2 text-xs font-medium text-white shadow-sm hover:bg-btnColor-hoveSecondary focus:outline-none 
              focus:ring-2 focus:ring-btnColor-secondary focus:ring-offset-2 md:px-8 md:text-sm lg:ml-8"
          >
            {isLandingPage ? "Medien finden" : "Suchen"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
