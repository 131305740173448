interface SectionProperties {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

export const Section = ({
  children,
  className = "",
  id,
}: SectionProperties) => {
  return (
    <div
      id={id}
      // remove shared max-w to shared TWcss class in scss
      className={`section-wrapper py-3 
      md:px-0
      md:py-10  
     md:text-left
        ${className}`}
    >
      {children}
    </div>
  );
};
// MediaRecommendation === TeamSection
//
