export const customColors = {
  white: "#ffffff",
  black: "#040404",
  grey: "rgb(158,158,158)",
  greyLight: "rgb(242, 242, 242)",
  purple: "rgb(135,101,210)",
  dark: "#000",
  brand: "rgb(10,25,178)",
  primary: "rgb(10,25,178)",
  primaryLight: "rgba(10,25,178, 0.1)",
  secondary: "rgb(255,189,51)",
  secondaryLight: "#5b6175",
  active: "rgb(194, 97, 29)",
  dark2: "#061034",
  border: "#cccccc",
  titleColor: "#061034",
  activeBg: "rgb(226,249,241)",
  activeText: "rgb(0,202,131)",
  nightBlue: "#061034",
  lightBackground: "#f3f6f9",
  tagsFontColor: "#5b6175",
};

export default customColors;
