import { getImageUrl } from "~/utils";
import type { IThematicFoci } from "~/api/get_themenschwerpunkte_data.server";
// export interface ICardProp extends IMedia {
//   onClick: any;
// }

export const CardMinimal = ({ name, picture, subtitle }: IThematicFoci) => {
  const url = picture ? getImageUrl(picture.file.url) : "";
  // console.log("cpicture", url);
  if (!url) return null;
  return (
    <div
      //md:min-w-mediaCard
      className="break-inside relative w-full overflow-hidden rounded-[10px] 
      bg-white shadow-card transition-shadow duration-300 hover:bg-slate-100"
    >
      <img
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
        src={url}
        className="h-52 w-full object-cover"
        alt=""
      />

      <div className="border border-t-0 p-5">
        <h4
          aria-label="Category"
          className="mb-2 inline-block
           text-cardTitle font-bold text-textColor-primary transition-colors duration-200"
        >
          {name}
        </h4>
        <div
        // dangerouslySetInnerHTML={{
        //   __html:
        //     description.length > 200
        //       ? description.slice(0, 200).concat("...")
        //       : description.slice(0, 200),
        // }}
        // className=""
        >
          <p className="text-xs leading-5 text-textColor-light">
            {subtitle ? subtitle : "no description found"}
          </p>
        </div>
      </div>
    </div>
  );
};
